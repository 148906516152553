.App {
    background-color: #505050;
    height: 100vh;
}

.wrapping-container {
    background-color: #505050;
}

.code-input.form-control { color: #a713df !important;  font-size: 5em;
    text-align: center; ;
    }

.hints-wrapper {
    /*max-width: 2000px;*/
}
.code-input-wrapper {
    max-width: 600px;
}

.submit-button.btn {
    font-size: 2em;
}

.purple-button.btn {
    background-color: #a713df;
    border: none;
}

.purple-button.btn:hover {
  background-color: #7e10aa;

}

.purple-button.btn:active {
    background-color: #7e10aa !important;
}

.outline-button.btn {
    border-color: #7e10aa;
    color: #7e10aa;
}

.outline-button.btn:hover {
    border-color: #7e10aa;
    color: #7e10aa;
    background-color: #f8eaff;

}

.outline-button.btn:active {
    border-color: #7e10aa;
    color: #7e10aa;
    background-color: #f8eaff !important;
}

.action-header:hover {
    cursor: pointer;
}

.code-text {
    color: white;
    font-size: 24px;
}

.card-heading {
    min-height: 3em;
    align-items: center !important;
    justify-content: center !important;
}

.card-heading:hover {
    cursor: pointer;
    color: #7e10aa;
}

.hide-icon {
    transform: rotate(180deg);
    transition: transform 600ms ease;
}

.show-icon {
    transform: rotate(0deg);
    transition: transform 600ms ease;
}

.congrats-text {
    color: white;
    max-width: 100%;
    font-size: min(64px, 9vw);

}

.congrats-letter {
    color: white;
    animation: 2s anim 1;
    display: inline-block;
}


@keyframes anim {
    0% {
        color: #c270c5;
        transform: rotate(0deg);
    }
    20%{ color: #efa47f; }
    40%{ color: #5ba15b;}
    60%{ color: #b46fdc;}
    100% {
        color: #ffffff;
        transform: rotate(360deg);
    }
}

.success-message-container.container {
    font-size: min(4vw, 22px);
    max-width: 700px;
    animation: fadeIn 4s;
}

@keyframes fadeIn {
    0% { opacity:0; }
    66% { opacity:0; }
    100% { opacity:1; }
}


.content-main {
    position: relative;
    margin: 40px auto;

    .content-box {
        font-size: min(4vw, 24px);
        font-style: italic;
        height: auto;
        padding: 20px;
        background: white;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.5);
    }
}

.card-link.card:hover  {
    background-color: #efefef;
    cursor: pointer ;
}

.navbar-brand img:hover {
    cursor: help !important;
}

.question-on-hover:hover {
    cursor: help !important;
}